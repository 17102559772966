var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "ipcUser-data";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"menu-Main-login\">\n		<div class=\"openProfile profile-name\">\n			<span class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconClass") || (depth0 != null ? lookupProperty(depth0,"iconClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconClass","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":29}}}) : helper)))
    + "\"></span>\n			<p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"userName") || (depth0 != null ? lookupProperty(depth0,"userName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userName","hash":{},"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":18}}}) : helper)))
    + "</p>\n			<b class=\"button\"></b>\n		</div>\n		<ul class=\"menuItem\">\n			<li><a href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"myProfile") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\"> "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"myProfile") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a> </li>				\n			<li><a href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"SignOut") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\"> "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"SignOut") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a> </li>				\n		</ul>\n	</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ipcUser") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":14,"column":1},"end":{"line":22,"column":1}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"ipcSection") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" id=\"labelSignOut\" class=\"t-control-link tile-tagp-sign-out tile-quick-link-item t-font-s t-line-height-m l-padding-three-quarters l-display-inline-block\">\n			<span class=\"t-quick-link-text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"ipcSection") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</span>\n		</a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"tagpSection") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" id=\"labelSignIn\" class=\"t-control-link tile-tagp-sign-out tile-quick-link-item t-font-s t-line-height-m l-padding-three-quarters l-display-inline-block\">\n			<span class=\"t-quick-link-text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"tagpSection") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</span>\n		</a>\n	";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"signOutHeader\" class=\"profile-Head "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ipcUser") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":44},"end":{"line":1,"column":78}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isExternalUser") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":22,"column":8}}})) != null ? stack1 : "")
    + "</div>\n\n	    \n";
},"useData":true});