/* Component Dependencies */
var tagpSignOutTemplate = require('templates/tagpSignOut.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({

  type: 'tagpSignOut',
  template: {
    'tagpSignOut': tagpSignOutTemplate
  },
	events: {
		'mouseenter .openProfile': 'OpenMenuProfile',
		'mouseleave .menu-Main-login': 'CloseMenuProfile',
	},
  unBindEvents: function($) {
    $('[data-component-id="' + this.$el.data('component-id') + '"] *').off();
    $('[data-component-id="' + this.$el.data('component-id') + '"]').off();
  },
  bindEvents: function() {
    var urlData = window.location.href;
    if((!urlData.includes("displaycontactipc.mi")) && (urlData.substr(-6) == "ipc.mi" || urlData.includes("ipc.mi"))){      
      this.$el.find("#labelSignIn").hide()
    }  
    $(window).on('load', function () {
      var helinkchange1 = document.getElementsByTagName('a'); 
      var signinName = document.getElementById('labelSignIn');
      function urlsplit(data){
        var url = data.split('/');
        var urldata = url[0];
        var urldata2 = url[2];
        var urldatanew = urldata + '//' + urldata2;
        return urldatanew;
      }
      for(var i = 0; i < helinkchange1.length;i++){        
          if(urlData.includes("travelagents")){     
            if(!signinName){
              if(helinkchange1[i].href.includes("hotelexcellence.marriott.com")){
                helinkchange1[i].setAttribute('href',urlsplit(helinkchange1[i].href)+'/home')
              }
            }else{
              if(helinkchange1[i].href.includes("hotelexcellence.marriott.com")){
                helinkchange1[i].setAttribute('href',urlsplit(helinkchange1[i].href));
              }
            }           
          }else{
            if(!signinName){
              if(helinkchange1[i].href.includes("meetings-excellence.marriott.com")){
                helinkchange1[i].setAttribute('href',urlsplit(helinkchange1[i].href)+'/home');
              }
            }else{
              if(helinkchange1[i].href.includes("meetings-excellence.marriott.com")){
                helinkchange1[i].setAttribute('href',urlsplit(helinkchange1[i].href));
              }
            }
          }
      }
    });  
    
  },  
  
  OpenMenuProfile: function(e) {
      this.$el.find(".menuItem").show();
      this.$el.find(".menuItem").addClass('showItem');
      this.$el.find(".openProfile").addClass('show');
  },
  CloseMenuProfile: function(e) {
      this.$el.find(".menuItem").hide();
      this.$el.find(".menuItem").removeClass('showItem');
      this.$el.find(".openProfile").removeClass('show');
},
});